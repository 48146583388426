import * as React from "react"
import classnames from "classnames"
import { FiMapPin } from "react-icons/fi"
import ReactHtmlParser from "react-html-parser"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

import Paragraph from "../atoms/paragraph"
import Title from "../atoms/title"
import transform from "../../utils/htmlParser"
import { ProjectsItemProps } from "../../interfaces/ProjectsItemProps"

const ProjectCard: React.FC<{
  data: ProjectsItemProps
  className?: string
}> = ({ data, className }) => {
  const BgImg = data.thumbnail && getImage(data.thumbnail.localFile)
  return (
    <div
      className={classnames(
        "shadow-lg border-b border-secondary bg-white max-w-sm",
        className
      )}
      style={{ minWidth: 250 }}
    >
      <div className="z-0 overflow-hidden h-32 sm:h-48 lg:h-56 2xl:h-64 relative">
        {BgImg && (
          <div className="absolute top-0 z-10 w-full h-full">
            <GatsbyImage
              style={{ minHeight: "100%" }}
              image={BgImg}
              alt={data.thumbnail.altText}
            />
          </div>
        )}
      </div>
      <div className="z-10 bg-white p-s">
        {data.location && (
          <div className="px-xs py-xxs flex bg-white -mt-m w-max relative">
            <FiMapPin className="text-primary mr-xxs" />
            <Paragraph className="text-xs">{data.location}</Paragraph>
          </div>
        )}
        {data.title && (
          <Title className="mt-s mb-xs" as="h6">
            {data.title}
          </Title>
        )}
        {data.text &&
          ReactHtmlParser(data.text, {
            decodeEntities: true,
            transform,
          })}
      </div>
    </div>
  )
}

export default ProjectCard
