import * as React from "react"
import { FiStar } from "react-icons/fi"

import Paragraph from "../atoms/paragraph"

const Rating: React.FC<{ rating?: number }> = ({ rating }) => {
  return (
    <div className="flex ml-m my-s text-lg items-center">
      <FiStar className="text-yellow text-xl fill-current mr-xs" />
      <div>
        <Paragraph className="font-black flex mb-0 leading-none">
          {rating || "4,7"} <span className="text-xs mt-xxs mb-0">/5</span>
        </Paragraph>
        <Paragraph className="text-sm italic">Avis clients</Paragraph>
      </div>
    </div>
  )
}

export default Rating
