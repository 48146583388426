import * as React from "react"
import classnames from "classnames"
import { FiArrowRight, FiArrowLeft } from "react-icons/fi"
import Slider from "react-slick"

import SectionTitle from "../molecules/sectionTitle"
import { TestimonialsProps } from "../../interfaces/TestimonialsProps"
import TestimonialCard from "../molecules/testimonialCard"

const Testimonials: React.FC<TestimonialsProps> = ({ data, className }) => {
  function ArrowLeft(props: any) {
    const { className, onClick } = props
    return (
      <FiArrowRight
        className={classnames("alt", className)}
        onClick={onClick}
      />
    )
  }
  function ArrowRight(props: any) {
    const { className, onClick } = props
    return (
      <FiArrowLeft className={classnames("alt", className)} onClick={onClick} />
    )
  }
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    centerMode: true,
    centerPadding: "0",
    slidesToScroll: 1,
    nextArrow: <ArrowLeft />,
    prevArrow: <ArrowRight />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }
  if (data) {
    return (
      <div className={classnames("pt-xl pb-l overflow-hidden", className)}>
        {data.sectionTitle && (
          <div className="container">
            <SectionTitle {...data.sectionTitle} className="text-center" />
          </div>
        )}
        {data.testimonials.length > 1 ? (
          <div className="mx-m sm:mx-l lg:mx-xl mt-l">
            <link
              rel="stylesheet"
              type="text/css"
              charSet="UTF-8"
              href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
            />
            <link
              rel="stylesheet"
              type="text/css"
              href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
            />
            <Slider {...settings}>
              {data.testimonials.map((el, i) => {
                return <TestimonialCard data={el} key={i} />
              })}
            </Slider>
          </div>
        ) : (
          <div className="mx-auto max-w-lg">
            {data.testimonials.map((el, i) => {
              return <TestimonialCard data={el} key={i} />
            })}
          </div>
        )}
      </div>
    )
  } else {
    return null
  }
}

export default Testimonials
