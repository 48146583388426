import * as React from "react"
import classnames from "classnames"
import { FaQuoteLeft } from "react-icons/fa"

import Paragraph from "../atoms/paragraph"
import { TestimonialItemProps } from "../../interfaces/TestimonialItemProps"

const TestimonialCard: React.FC<{
  data: TestimonialItemProps
  className?: string
}> = ({ data, className }) => {
  if (data) {
    return (
      <div
        className={classnames(
          "px-m py-s m-s border text-light-gray text-center",
          className
        )}
      >
        <div className="inline-block bg-white p-m mx-auto -mt-l">
          <FaQuoteLeft className="text-xl" />
        </div>
        <Paragraph className="text-dark-gray">{data.testimonial}</Paragraph>
        <div className="mx-auto my-s border w-0 h-4"></div>
        <Paragraph className="text-dark-gray uppercase">{data.name}</Paragraph>
      </div>
    )
  } else {
    return null
  }
}

export default TestimonialCard
